import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PAGE_CONTEXT from 'core/datasets/page_context';
import ItemWithArrowList from 'core/components/lists/ItemWithArrowList';
import useQueryServiceRequestTypes from 'serviceRequest/api/gql/useQueryServiceRequestTypes';
import { LoadingIndicator } from 'core/components/LoadingIndicator';
import useSlidePanel from 'core/components/SlidePanel/hooks/useSlidePanel/useSlidePanel';
import SlidePanel from 'core/components/SlidePanel';
import { IServiceRequestTypeInList } from 'serviceRequest/types/serviceRequest';

interface ServiceTypesListProps {
    handleTypesClose: () => void;
}

const ServiceTypesList = ({ handleTypesClose }: ServiceTypesListProps): JSX.Element => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    const { data, loading, serviceRequestTypeTitlesMap } = useQueryServiceRequestTypes();
    const { getSlidePanelArgs, handleClose, getComponent } = useSlidePanel({
        pageContext: PAGE_CONTEXT.SERVICE_REQUEST,
    });

    const slidePanelArgs = getSlidePanelArgs();
    const ServiceRequestForm = getComponent();

    const [serviceType, setServiceType] = useState<null | { id: number; title: string }>(null);

    const clickHandler = (service: IServiceRequestTypeInList) => {
        setServiceType(service);

        handleClose();
    };

    const titlesList = data
        .filter(({ isActive }) => isActive)
        .map(({ id, isDefault, internalTitle }) => {
            const title = isDefault
                ? t(`maintenanceServices.types.${internalTitle}`)
                : serviceRequestTypeTitlesMap[String(id)][language] || serviceRequestTypeTitlesMap[String(id)]['en'];

            return { id, title };
        });

    if (loading) return <LoadingIndicator />;

    return (
        <>
            <ItemWithArrowList data={titlesList} customClasses={'service-types-list'} clickHandler={clickHandler} />

            <SlidePanel {...slidePanelArgs}>
                <div>
                    {ServiceRequestForm ? (
                        <ServiceRequestForm handleParentPanelClose={handleTypesClose} serviceType={serviceType} />
                    ) : null}
                </div>
            </SlidePanel>
        </>
    );
};

export default ServiceTypesList;
