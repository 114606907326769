import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogoLogin } from '../../core/components/Logo';
import { isMobileWidthSelector } from '../../core/redux/reducers/coreReducer';
import { CORE } from 'core/datasets/action';

import LoginForm from './components/LoginForm';
import Intro from '../../core/components/Intro';

import './Login.css';

const Login = (): JSX.Element => {
    const dispatch = useDispatch();
    const isMobileWidth = useSelector(isMobileWidthSelector);

    useEffect(() => {
        dispatch({ type: CORE.LAYOUT_DATA_REFRESHED });
    }, [dispatch]);

    if (isMobileWidth) {
        return <MobileLogin />;
    }

    return <DesktopLogin />;
};

export function MainLogo(): JSX.Element {
    return (
        <div className="login-page__placeholder">
            <LogoLogin
                classes="company-intro__logo company-intro__logo--white company-intro__logo"
                width="165px"
                height="165px"
            />
        </div>
    );
}

function MobileLogin(): JSX.Element {
    return (
        <div className={`login-page login-page--mobile`}>
            <Intro classes="login-page__intro" />
            <MainLogo />
            <div className={`login-page__login-form-container login-page__login-form-container--mobile`}>
                <LoginForm />
            </div>
        </div>
    );
}

function DesktopLogin(): JSX.Element {
    return (
        <div className={`login-page login-page--desktop`}>
            <Intro classes="login-page__intro" />
            <MainLogo />
            <div className={`login-page__login-form-container login-page__login-form-container--desktop`}>
                <LoginForm />
            </div>
        </div>
    );
}

export default Login;
