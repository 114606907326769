import { ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from 'tenantUser/redux/userProfileActions';
import { LangValueType } from 'tenantUser/types/redux/userProfileStates';
import { AppState } from 'core/redux/rootReducer';
import { useResources } from 'resources/context';

import './styles/LangSelect.css';
import { useConfig } from 'resources/config';

const LangSelect: LangSelect = ({ variant }: LangSelectProps) => {
    const { WorldIcon } = useResources();
    const allowedLanguages = useConfig().languages;

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const { currentLang } = useSelector(({ userProfile }: AppState) => userProfile);

    const changeHandler = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        const lang = event.target.value as string;
        i18n.changeLanguage(lang).catch((err) => console.log(err));
        dispatch(changeLanguage(lang as LangValueType));
    };

    return (
        <div
            className={'language-setting language-setting--' + (variant ? variant : LANG_SELECT_VARIANT.TEXT_OUTLINED)}
        >
            {!variant || variant === LANG_SELECT_VARIANT.TEXT_OUTLINED ? (
                <InputLabel className="language-setting-select-label" id="language-setting-select-label">
                    {t('settings.selectLanguage')}
                </InputLabel>
            ) : (
                <WorldIcon className="language-setting__icon" />
            )}
            <Select
                className="settings-item language-setting__select "
                labelId="language-setting-select-label"
                id="language-setting-select"
                onChange={changeHandler}
                value={currentLang}
                variant={variant ? variant : LANG_SELECT_VARIANT.TEXT_OUTLINED}
            >
                {allowedLanguages.map((code) => {
                    return (
                        <MenuItem key={code} value={code}>
                            {t(`settings.languages.${code}`)}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
};
export default LangSelect;

type LangSelect = (props: LangSelectProps) => JSX.Element;
export type LangSelectProps = {
    variant?: LANG_SELECT_VARIANT;
};
// material ui styles for the select component
export enum LANG_SELECT_VARIANT {
    'TEXT_OUTLINED' = 'outlined',
    'ICON_STANDARD' = 'standard',
}
