import moment from 'moment';
import { DateTime } from 'luxon';

export const DB_DATE_FORMAT = 'YYYY-MM-DD';
export const DB_LUX_DATE_FORMAT = 'yyyy-MM-dd';
export const DB_LUX_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

const DB_DATE_TIME_FORMAT = DB_DATE_FORMAT + ' HH:mm';
const LARVA_DATE_TIME_FORMAT = DB_DATE_FORMAT + 'THH:mm';
export const APP_DATE_FORMAT = 'DD.MM.YYYY';
export const APP_DATE_TIME_FORMAT = APP_DATE_FORMAT + ' HH:mm';
export const APP_TIME_FORMAT = 'HH:mm';

export const APP_LUX_DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm:ss';
export const APP_LUX_DATE_TIME_SHORT_FORMAT = 'dd.MM.yyyy HH:mm';
export const APP_LUX_DATE_FORMAT = 'dd.MM.yyyy';
export const PERIOD_UNIT = {
    YEAR: 'YEAR',
    MONTH: 'MONTH',
    QUARTER: 'QUARTER',
};

export const MONTH_YEAR_LUX_FORMAT = 'LLL yyyy';

export { formatAppDateTimeToAppDate } from './formatAppDateTimeToAppDate';
export { formatDbDateTimeToAppDate } from './formatDbDateTimeToAppDate';
export { formatDateTimeFormatToLocalFormat } from './formatDateTimeFormatToLocalFormat';

const datesHelper = (): FormatDateReturn => {
    /**
     * Change db date format to the app date format
     * @deprecated DateTime from luxon should be used instead
     * @param date string
     * @return string
     */
    const formatDate = (date: undefined | string): string =>
        !!date ? moment(date, DB_DATE_FORMAT).format(APP_DATE_FORMAT) : '';

    /**
     * @deprecated DateTime from luxon should be used instead
     * @param date
     */
    const formatDateTime = (date: undefined | string): string =>
        !!date ? moment(date, DB_DATE_TIME_FORMAT).format(APP_DATE_TIME_FORMAT) : '';

    const shortDbDateFormat = (date: string) => moment(date, DB_DATE_FORMAT).format(DB_DATE_FORMAT);
    const getCurrentDate = () => moment().format(APP_DATE_FORMAT);
    const getCurrentDBDate = () => moment().format(DB_DATE_FORMAT);
    /**
     * Compare date with the current date
     * Returns true if the date is today's or tomorrow's
     * @param date string
     * @return boolean
     */
    const isFutureDate = (date: string): boolean =>
        DateTime.fromSQL(date).isValid && DateTime.fromSQL(date).diff(DateTime.now().startOf('day'), 'day').days > 0;
    const isTodayOrFuture = (date: string): boolean =>
        DateTime.fromSQL(date).isValid && DateTime.fromSQL(date).diff(DateTime.now().startOf('day'), 'day').days >= 0;
    const isTomorrowOrFuture = (date: string): boolean =>
        DateTime.fromSQL(date).isValid && DateTime.fromSQL(date).diff(DateTime.now().startOf('day'), 'day').days > 1;
    const isToday = (date: string): boolean =>
        DateTime.fromSQL(date).isValid && DateTime.fromSQL(date).diff(DateTime.now().startOf('day'), 'day').days === 0;

    /**
     * @deprecated DateTime from luxon should be used instead
     * @param date
     */
    const getYear = (date: string): string => moment(date, DB_DATE_FORMAT).format('YYYY');
    /**
     * @deprecated DateTime from luxon should be used instead
     * @param date
     */
    const getMonth = (date: string): string => (moment(date, DB_DATE_FORMAT).month() + 1).toString();
    /**
     * @deprecated DateTime from luxon should be used instead
     * @param date
     */
    const getDay = (date: string): string => moment(date, DB_DATE_FORMAT).day().toString();
    /**
     * @deprecated DateTime from luxon should be used instead
     */
    const getCurrentDateTime = () => moment().format(DB_DATE_TIME_FORMAT);

    const isFirstDateBeforeSecond = (date1: string, date2: string) =>
        moment(date1, DB_DATE_FORMAT).isBefore(moment(date2, DB_DATE_FORMAT));
    const isFirstDateSameOrBeforeSecond = (date1: string, date2: string) =>
        moment(date1, DB_DATE_FORMAT).isSameOrBefore(moment(date2, DB_DATE_FORMAT));

    /**
     * @deprecated use instead the DateTime from luxon instead
     * @param date
     */
    const formatLarvaDateTime = (date: string) => {
        return moment(date, LARVA_DATE_TIME_FORMAT).format(APP_DATE_TIME_FORMAT);
    };

    const formatTimestampDateTime = (timestamp: number): string | null => {
        if (!timestamp || typeof timestamp !== 'number') return null;

        const dt = DateTime.fromMillis(timestamp);

        const { hours } = dt.diffNow('hours');
        if (Math.abs(hours) < 24) return dt.toFormat(APP_TIME_FORMAT);

        return dt.toFormat('dd.MM.yyyy HH:mm');
    };

    return {
        formatDate,
        formatDateTime,
        isFutureDate,
        isFirstDateSameOrBeforeSecond,
        getYear,
        getMonth,
        getDay,
        getCurrentDateTime,
        isFirstDateBeforeSecond,
        shortDbDateFormat,
        getCurrentDBDate,
        getCurrentDate,
        formatLarvaDateTime,
        formatTimestampDateTime,
        isTodayOrFuture,
        isTomorrowOrFuture,
        isToday,
    };
};

export const {
    formatDate,
    formatDateTime,
    isFirstDateSameOrBeforeSecond,
    isFutureDate,
    getYear,
    getMonth,
    getDay,
    getCurrentDateTime,
    getCurrentDate,
    isFirstDateBeforeSecond,
    shortDbDateFormat,
    getCurrentDBDate,
    formatLarvaDateTime,
    formatTimestampDateTime,
    isTodayOrFuture,
    isTomorrowOrFuture,
    isToday,
} = datesHelper();

export default datesHelper;

type FormatDateReturn = {
    /**
     * Change db date format to the app date format
     * @deprecated DateTime from luxon should be used instead
     * @param date string
     * @return string
     */
    formatDate: (date?: string) => string;
    /**
     * @deprecated DateTime from luxon should be used instead
     * @param date
     */
    formatDateTime: (date?: string) => string;

    /**
     * Returns shortened db format (without time)
     * @param date
     */
    shortDbDateFormat: (date: string) => string;

    /**
     * Returns current date in db format
     */
    getCurrentDBDate: () => string;
    /**
     * Returns current date in app format
     */
    getCurrentDate: () => string;
    /**
     * Compare date with the current date
     * Returns true if tomorrow's dates
     * @param date string
     * @return boolean
     */
    isFutureDate: (date: string) => boolean;

    /**
     * Compare date with the current date
     * Returns true if the date is today's or tomorrow's
     * @param date string
     * @return boolean
     */
    isTodayOrFuture: (date: string) => boolean;
    isTomorrowOrFuture: (date: string) => boolean;
    isToday: (date: string) => boolean;
    /**
     * Extract year from db date string
     * @param date
     * @deprecated DateTime from luxon should be used instead
     * @return string
     */
    getYear: (date: string) => string;
    /**
     * Extract month as a number from db date string, start from 1
     * @param date
     * @deprecated DateTime from luxon should be used instead
     * @return string
     */
    getMonth: (date: string) => string;
    /**
     * Extract day as a number from db date string
     * @param date
     * @deprecated DateTime from luxon should be used instead
     * @return string
     */
    getDay: (date: string) => string;

    /**
     * Compares dates to define if the first one is before the second one
     * @param date1
     * @param date2
     */
    isFirstDateBeforeSecond: (date1: string, date2: string) => boolean;

    /**
     * Check if dates are the same
     * @param date1
     * @param date2
     */
    isFirstDateSameOrBeforeSecond: (date1: string, date2: string) => boolean;
    getCurrentDateTime: () => string;

    formatLarvaDateTime: (date: string) => string;
    formatTimestampDateTime: (timestamp: number) => string | null;
};
