import { ENVIRONMENT, FIREBASE, MESSAGING } from 'core/datasets/action';
import { AppState } from 'core/redux/rootReducer';
import { child, get, getDatabase, ref } from 'firebase/database';
import { onItemChangedWatcher } from 'firebase/saga/workers/onItemChangedWatcher';
import { messagingActions } from 'messaging/redux/messagingActions';
import { AddChannelKeyAction } from 'messaging/types/redux/messagingActions';
import { call, fork, put, select, take } from 'redux-saga/effects';

interface ChannelDataFromFirebase {
    last_message: string;
    last_updated: number;
}

export function* fetchChannelDataByKey({ payload }: AddChannelKeyAction): Generator {
    const dbRef = ref(getDatabase());
    const dbKey = `channels/${payload}/meta`;
    const result = yield call(get, child(dbRef, dbKey));
    const channelData = (result as any).val() as ChannelDataFromFirebase;

    let firebaseUser = (yield select(({ firebase }: AppState) => firebase.user)) as any;
    if (!firebaseUser) {
        yield take([FIREBASE.SET_FIREBASE_USER]);
        firebaseUser = (yield select(({ firebase }: AppState) => firebase.user)) as any;
    }

    let displayName = (yield select(
        ({ environment }: AppState) => environment.messagingSettings.displayName,
    )) as string;
    if (displayName === null) {
        yield take(ENVIRONMENT.SET_ENVIRONMENT_SETTINGS);
        displayName = (yield select(
            ({ environment }: AppState) => environment.messagingSettings.displayName,
        )) as string;
    }

    const lastActivityResult = yield call(get, child(dbRef, `channels/${payload}/lastActivity/${firebaseUser.uid}`));
    const lastActivity = (lastActivityResult as any)?.val() ?? 0;

    yield put(
        messagingActions.addChannel({
            id: payload,
            name: displayName ?? '',
            messages: [],
            lastMessage: channelData?.last_message ?? null,
            lastMessageTimestamp: channelData?.last_updated ?? null,
            hasUnread: false,
            viewed: lastActivity,
        }),
    );

    yield fork(
        onItemChangedWatcher,
        dbKey,
        MESSAGING.CHANGE_CHANNEL_FIELD,
        (data) => {
            const fieldMatch: { [key: string]: string } = {
                last_message: 'lastMessage',
                last_updated: 'lastMessageTimestamp',
            };
            if (data.key && fieldMatch[data.key])
                return { channelId: payload, field: fieldMatch[data.key], value: data.val() };
            return null;
        },
        true,
    );
}
