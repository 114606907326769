// import DoorAccessForm from 'pages/DoorCode/components/forms/DoorAccessForm';
import { useEffect, useState, FC, ElementType, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FiltersList from '../../../lists/FiltersList';
import PageContextType from '../../../../types/pageContext';
import PAGE_CONTEXT_ACTIONS from '../../../../config/page_context_actions';
import PAGE_CONTEXT_ACTION from '../../../../datasets/page_context_action';
import { PageContextActions } from 'core/types/pageContextActions';
import SettingsList from '../../../../../pages/Settings/components/SettingsList';
import ServiceTypesList from '../../../../../pages/Service/components/ServiceTypesList';
import ServiceRequestForm from '../../../../../pages/Service/components/ServiceRequestForm';
import { ServiceRequestFormProps } from 'pages/Service/components/ServiceRequestForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'core/redux/rootReducer';
import { resetSlideInContext } from 'core/redux/actions/slideInContextActions';

interface UseSlidePanelProps {
    pageContext: PageContextType;
}

export const useSlidePanel = ({ pageContext }: UseSlidePanelProps): useSlidePanelReturnType => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const slideInContext = useSelector(({ slideInContext }: AppState) => slideInContext.contextAction);

    const [isOpen, setIsOpen] = useState(false);

    const handleClose = useCallback(() => {
        if (isOpen) {
            dispatch(resetSlideInContext());
        }

        setIsOpen(!isOpen);
    }, [dispatch, isOpen]);

    const [contextAction, setContextAction] = useState<PageContextActions>();

    const slidePanelContentComponents: { [index: string]: JSX.Element } = useMemo(
        () => ({
            [PAGE_CONTEXT_ACTION.FILTER]: <FiltersList context={pageContext} />,
            [PAGE_CONTEXT_ACTION.CHANGE_USER_PREFERENCES]: <SettingsList />,
            [PAGE_CONTEXT_ACTION.SELECT_SERVICE_TYPE]: <ServiceTypesList handleTypesClose={handleClose} />,
            [PAGE_CONTEXT_ACTION.FILTER_SERVICES]: <FiltersList context={pageContext} />,
            [PAGE_CONTEXT_ACTION.SHOW_HIDDEN_MENU]: <>Menu</>,
        }),
        [handleClose, pageContext],
    );

    const slidePanelContentFC: { [index: string]: FC<ServiceRequestFormProps> } = {
        [PAGE_CONTEXT_ACTION.MAKE_SERVICE_REQUEST]: ServiceRequestForm,
    };

    useEffect(() => {
        //@ts-ignore
        setContextAction(() =>
            !!slideInContext
                ? slideInContext
                : pageContext in PAGE_CONTEXT_ACTIONS
                ? PAGE_CONTEXT_ACTIONS[pageContext as keyof typeof PAGE_CONTEXT_ACTIONS]
                : null,
        );
    }, [pageContext, slideInContext]);

    const getArgsForContext = useCallback(
        (context?: PageContextType) => {
            const currentContext = !!context ? context : pageContext;
            const currentContextAction = !!context ? 'showHiddenMenu' : contextAction;
            return {
                panelId: `${contextAction}-${currentContext}`,
                headerText: !!currentContextAction ? t('slidePanel.' + currentContextAction) : '',
                handleClose,
                isOpen,
            };
        },
        [contextAction, handleClose, isOpen, pageContext, t],
    );

    const getContent = useCallback(
        (context?: PageContextType) => {
            const currentContextAction = !context
                ? contextAction
                : context in PAGE_CONTEXT_ACTIONS
                ? PAGE_CONTEXT_ACTIONS[pageContext as keyof typeof PAGE_CONTEXT_ACTIONS]
                : null;
            return !!currentContextAction
                ? slidePanelContentComponents[currentContextAction as PageContextActions]
                : null;
        },
        [contextAction, pageContext, slidePanelContentComponents],
    );

    const getComponent = () => {
        return !!contextAction ? slidePanelContentFC[contextAction as PageContextActions] : null;
    };

    return {
        getSlidePanelArgs: getArgsForContext,
        getSlidePanelContent: getContent,
        handleClose,
        getComponent,
        isOpen,
    };
};
export default useSlidePanel;

type useSlidePanelReturnType = {
    getSlidePanelArgs: (context?: PageContextType) => {
        panelId: string;
        headerText: string;
        handleClose: () => void;
        isOpen: boolean;
    };
    getSlidePanelContent: (context?: PageContextType) => JSX.Element | null;
    handleClose: () => void;
    getComponent: () => ElementType | null;
    isOpen: boolean;
};
