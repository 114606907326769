import { ChangeFilterAction, ResetFilterAction, SetFilterGroupsAction } from '../../types/redux/filterActions';
import { FILTER } from 'core/datasets/action';
import { Filter as FilterProps, FilterCategoryType, FilterGroupType } from '../../types/filter';

export const changeFilterValue = (filter: FilterProps): ChangeFilterAction => {
    return {
        type: FILTER.CHANGE_FILTER,
        payload: filter,
    };
};

interface resetFilterGroupProps {
    filterGroupName: keyof FilterGroupType;
    context: FilterCategoryType;
}
export const resetFilterGroup = ({ filterGroupName, context }: resetFilterGroupProps): ResetFilterAction => {
    return {
        type: FILTER.RESET_FILTER_GROUP,
        payload: {
            context,
            filterGroupName,
        },
    };
};

interface SetServiceRequestFilterGroupsProps {
    groups: FilterGroupType;
    context: FilterCategoryType;
}
export const setServiceRequestFilterGroups = ({
    groups,
    context,
}: SetServiceRequestFilterGroupsProps): SetFilterGroupsAction => {
    return {
        type: FILTER.SET_FILTER_GROUPS,
        payload: {
            groups,
            context,
        },
    };
};
