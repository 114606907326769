import { NotificationAlert } from 'core/classes/NotificationAlert';
import { AlertLevel, AlertNotice } from 'core/components/modalsPortalsNotices/AlertNotice';
import INotificationAlertInstanceData from 'core/types/INotificationInstanceData';
import { useSelector } from 'react-redux';
import { AppState } from 'core/redux/rootReducer';
import { Capacitor } from '@capacitor/core';
import {
    ActionPerformed,
    PermissionStatus,
    PushNotifications,
    PushNotificationSchema,
    Token,
} from '@capacitor/push-notifications';
import { useEffect, useState } from 'react';
import { useMutationPushNotificationToken } from 'core/components/PushNotification/api/gql/useMutationPushNotificationToken/useMutationPushNotificationToken';
import { getApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, MessagePayload } from 'firebase/messaging';
import { serverKey } from 'core/config/firebase';
import { useResources } from 'resources/context';
import { useCallback } from 'react';
import { useRef } from 'react';
import firebase from 'firebase/compat';
import Unsubscribe = firebase.Unsubscribe;
import { environmentSelectors } from 'environment/redux/environmentSelectors';

const notificationRegisterError = (error: unknown) => console.log('PUSH NOTIFICATION REGISTRATION ERROR', { error });

const isGranted = (response: PermissionStatus) => response.receive === 'granted';

const PushNotification = (): JSX.Element => {
    const { SmartHomeIcon } = useResources();

    const isLoggedIn = useSelector(({ auth }: AppState) => auth.isLoggedIn);
    const isMessagingEnabled = useSelector(environmentSelectors.isMessagingEnabled);

    const { savePushNotificationToken } = useMutationPushNotificationToken();

    const [notification, setNotification] = useState<null | INotificationAlertInstanceData>(null);

    const onClose = () => setNotification(null);

    const notificationReceivedTenantApp = useCallback(
        (payload: MessagePayload) => {
            if (isLoggedIn && isMessagingEnabled) {
                setNotification(() => ({
                    title: payload?.notification?.title as string,
                    message: payload?.notification?.body as string,
                    level: NotificationAlert.INFO,
                }));
            }
        },
        [isLoggedIn, isMessagingEnabled],
    );

    const notificationReceived = useCallback(
        (payload: PushNotificationSchema) => {
            if (isLoggedIn && isMessagingEnabled) {
                setNotification(() => ({
                    title: payload.title as string,
                    message: payload.body as string,
                    level: NotificationAlert.INFO,
                }));
            }
        },
        [isLoggedIn, isMessagingEnabled],
    );

    const handleAction = (action: ActionPerformed) => {
        console.log('PUSH NOTIFICATION ACTION PERFORMED: ', { action });
    };

    const unsubscribe = useRef<Unsubscribe | null>(null);

    useEffect(() => {
        if (Capacitor.getPlatform() === 'web') {
            try {
                const firebaseApp = getApp();
                if (!firebaseApp) {
                    console.log('No firebase application initialised for push notifications');
                    return;
                }

                const messaging = getMessaging(getApp());

                getToken(messaging, { vapidKey: serverKey })
                    .then((token) => savePushNotificationToken('tenant_app', token))
                    .catch((error: unknown) => {
                        console.warn('[Push notifications] ERROR: ', { error });
                    });

                unsubscribe.current = onMessage(messaging, notificationReceivedTenantApp);
            } catch (error) {
                console.log('Firebase initialisation error: ', { error });
            }
        }

        if (Capacitor.getPlatform() !== 'web' && Capacitor.isPluginAvailable('PushNotifications')) {
            PushNotifications.checkPermissions()
                .then((response) => {
                    if (isGranted(response)) {
                        PushNotifications.register().catch(notificationRegisterError);
                        return;
                    }

                    PushNotifications.requestPermissions()
                        .then((response) => {
                            if (isGranted(response)) {
                                PushNotifications.register().catch(notificationRegisterError);
                            }
                        })
                        .catch((error) => {
                            console.error('Push notifications request permission error', { error });
                        });
                })
                .catch((error) => console.error('Failed to check permissions: ', { error }));

            PushNotifications.addListener('registration', (token: Token) =>
                savePushNotificationToken(Capacitor.getPlatform(), token.value),
            ).catch((error) => console.log('Push notification add listener to registration error', { error }));

            PushNotifications.addListener('registrationError', (error) => {
                console.error('Push notifications got registration error', { error });
            }).catch((error) => console.log('Push notification add listener to registrationError error', { error }));

            PushNotifications.addListener('pushNotificationReceived', notificationReceived).catch((error) =>
                console.log('Push notification add listener to pushNotificationReceived error', { error }),
            );

            PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
                handleAction(action);
            }).catch((error) =>
                console.log('Push notification add listener to pushNotificationReceived error', { error }),
            );
        }

        // Try to unsubscribe from all notifications. This works in app, but system/browser notifications
        // will still be visible.
        if (!isLoggedIn) {
            if (Capacitor.getPlatform() === 'web') {
                if (unsubscribe.current) {
                    unsubscribe.current();
                    unsubscribe.current = null;
                }
            }

            if (Capacitor.getPlatform() !== 'web' && Capacitor.isPluginAvailable('PushNotifications')) {
                PushNotifications.removeAllListeners().catch((error) =>
                    console.log('Remove push notification listeners error: ', { error }),
                );
            }
        }

        return () => {
            if (Capacitor.isPluginAvailable('PushNotifications')) {
                PushNotifications.removeAllListeners().catch((error) =>
                    console.log('Remove push notification listeners error: ', { error }),
                );
            }
            if (unsubscribe.current) {
                unsubscribe.current();
                unsubscribe.current = null;
            }
        };
    }, [isLoggedIn, isMessagingEnabled]);

    return (
        <AlertNotice
            autoHide={null}
            headerText={notification?.title}
            open={!!notification}
            level={NotificationAlert.INFO as AlertLevel}
            text={notification?.message || ''}
            onClose={onClose}
            alertIcon={<SmartHomeIcon />}
            location={{ horizontal: 'center', vertical: 'top' }}
        />
    );
};

export default PushNotification;
