import { ApolloError } from '@apollo/react-hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { prefillEmailField } from 'tenantUser/redux/authActions';
import { gql, useMutation } from '@apollo/client';

const resetUserPasswordMutationDocument = gql`
    mutation resetUserPassword($resetPasswordInput: ResetPasswordInput!) {
        resetUserPassword(resetPasswordInput: $resetPasswordInput) {
            isSuccessful
            message
            email
        }
    }
`;

interface ResetPasswordResponse {
    resetUserPassword: {
        isSuccessful: boolean;
        message: string | null;
        email: string | null;
    };
}

const useMutationResetUserPassword = (hash: string): MutationResetUserPasswordReturnType => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [resetPassword, { loading, error }] = useMutation(resetUserPasswordMutationDocument);
    const [responseError, setError] = useState<ApolloError | undefined>();

    const navigate = useNavigate();

    const handleResetPassword = (password: string) => {
        if (!password) {
            console.warn('Empty password: Skip reset mutation');
            return;
        }

        resetPassword({
            variables: {
                resetPasswordInput: {
                    password,
                    hash,
                },
            },
        })
            .then(({ data }) => {
                const { resetUserPassword } = data as ResetPasswordResponse;

                if (!resetUserPassword?.email) {
                    setError(
                        () =>
                            new ApolloError({
                                errorMessage: resetUserPassword?.message || 'Failed to reset password',
                            }),
                    );
                    return;
                }
                dispatch(prefillEmailField(resetUserPassword?.email));

                const successMessage = t('tenantUser.notices.resetPasswordSuccess');
                navigate('/login', { state: { success: successMessage } });
            })
            .catch((error: unknown) => console.error('Failed to reset password: ', { error }));
    };

    return { loading, error: error || responseError, handleResetPassword };
};

export default useMutationResetUserPassword;

type MutationResetUserPasswordReturnType = {
    loading: boolean;
    error?: ApolloError;
    handleResetPassword(password: string): void;
};
