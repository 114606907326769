import { makeComponentClasses } from 'core/utils/componentUtils';
import { useResources } from 'resources/context';

export interface ListItemWithArrowProps {
    text: string;
    clickHandler: () => void;
    customClasses?: string;
}

const ListItemWithArrow = ({ clickHandler, text, customClasses = '' }: ListItemWithArrowProps): JSX.Element => {
    const { HomeIcon } = useResources();
    const { base, iconTextClass, iconClass, textClass } = makeComponentClasses(
        {
            base: 'list-item-with-arrow-btn',
            iconTextClass: 'icon-text',
            iconClass: 'icon',
            textClass: 'text',
        },
        customClasses,
    );

    return (
        <a className={base} onClick={clickHandler}>
            <div className={iconTextClass}>
                <HomeIcon className={iconClass} />
                <span className={textClass}> {text} </span>
            </div>
            <span className={'bta-icon-chevron-right btn-link-with-arrow__icon--right'} />
        </a>
    );
};
export default ListItemWithArrow;
