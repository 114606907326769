import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useResources } from 'resources/context';

interface ExtraServiceIconProps {
    name: string;
}
const ExtraServiceIcon = ({ name }: ExtraServiceIconProps): JSX.Element => {
    const { HomeExtraServicesIcon } = useResources();
    return useMemo(
        () =>
            !!name && name in solidIcons ? (
                // @ts-ignore - fas icon type
                <FontAwesomeIcon size={'lg'} icon={solidIcons[`${name}`]} />
            ) : (
                <HomeExtraServicesIcon />
            ),
        [name],
    );
};
export default ExtraServiceIcon;
