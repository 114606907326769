import Portal from '../modalsPortalsNotices/Portal';
import { animated, useSpring } from 'react-spring';
import { useCallback, useEffect, useState } from 'react';
import { SlidePanel as ISlidePanel } from '../../types/slideinPanel';
import CloseObjectButton from '../buttons/CloseObjectButton';

const SlidePanel = ({
    headerText,
    children,
    handleClose,
    isOpen,
    panelId,
    customHeaderButtons,
    hasHeader = true,
}: ISlidePanel): JSX.Element | null => {
    const TIMEOUT_MS = 150;
    const portalId = `slide-in-panel__portal-root--${panelId}`;
    const overlayClass = `slide-in-panel__portal-root-overlay`;

    const [springTransform, setSpringTransform] = useSpring(() => ({ transform: 'translate3d(100%,0,0)' }));
    const [overlayProps, setOverlay] = useSpring(() => ({ opacity: 0 }));
    const [closed, setClosed] = useState(() => !isOpen);

    // gives time to finish closing animation before unmount the portal
    const setDelayedClosing = useCallback((isOpen: boolean) => {
        if (isOpen) return setClosed(!isOpen);
        return setTimeout(() => {
            setClosed(!isOpen);
        }, TIMEOUT_MS);
    }, []);

    useEffect(() => {
        setDelayedClosing(isOpen);
        setSpringTransform.start({ transform: isOpen ? 'translate3d(0%,0,0)' : 'translate3d(100%,0,0)' });
        setOverlay.start({ opacity: isOpen ? 1 : 0 });
    }, [isOpen, setSpringTransform, setDelayedClosing, setOverlay]);

    const HeaderButtons = useCallback((): JSX.Element => {
        return !!customHeaderButtons ? customHeaderButtons : <CloseObjectButton onClick={handleClose} />;
    }, [customHeaderButtons, handleClose]);

    const Header = useCallback((): JSX.Element | null => {
        if (hasHeader)
            return (
                <div className="slide-in-panel__header">
                    <h3 className="slide-in-panel__header-text">{headerText}</h3>
                    <HeaderButtons />
                </div>
            );
        return null;
    }, [HeaderButtons, hasHeader, headerText]);

    return !closed ? (
        <Portal id={portalId}>
            <animated.div className="slide-in-panel" style={springTransform}>
                <Header />
                <div className="slide-in-panel__content">{children}</div>
            </animated.div>
            <animated.div style={overlayProps} onClick={handleClose} className={overlayClass} />
        </Portal>
    ) : null;
};
export default SlidePanel;
