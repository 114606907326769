import { AgreementStatus } from 'agreement/AgreementStatus';
import { AgreementDetailsViewData } from 'agreement/classes/AgreementDetailsViewData';
import { AppState } from 'core/redux/rootReducer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { makeComponentClasses, makeComponentIds } from 'core/utils/componentUtils';
import { setRentalObjectSelectValue, setServiceTypeValue } from 'serviceRequest/redux/serviceRequestActions';
import IAgreementObjectInstanceData from 'agreementObject/types/IAgreementObjectData';
import useServiceRequestController from 'serviceRequest/hooks/useServiceRequestController/useServiceRequestController';
import useQueryServiceRequest from 'serviceRequest/api/gql/useQueryServiceRequest';
import SubmitButton from 'core/components/buttons/SubmitButton';
import ErrorNotice from 'core/components/modalsPortalsNotices/ErrorNotice';
import { FileUploadButton } from './FileUploadButton';
import { SelectedFiles } from './SelectedFiles';
import { AxiosError } from 'axios';
import { ApolloError } from '@apollo/react-hooks';
import { IServiceRequestTypeInList } from 'serviceRequest/types/serviceRequest';

export interface ServiceRequestFormProps {
    serviceType: IServiceRequestTypeInList;
    handleParentPanelClose: () => void;
    customClasses?: string;
    idBase?: string;
}
const ServiceRequestForm = ({
    serviceType,
    handleParentPanelClose,
    customClasses = '',
    idBase = '',
}: ServiceRequestFormProps): JSX.Element => {
    const { t } = useTranslation();

    // service requests should be available only with active agreements. And it always should be selected
    const coreSelectedAgreementId = useSelector(({ agreements }: AppState) => agreements.selectedAgreement?.id);
    const agreements = useSelector(({ agreements }: AppState) => agreements.map);
    const selectedObjectId = useSelector(({ agreementObjects }: AppState) => agreementObjects.selectedObjectID);
    const rentalObjectSelect = useSelector(
        ({ serviceRequests }: AppState) => serviceRequests.requestForm.rentalObjectSelect,
    );

    const {
        handleSelectChange,
        handleSubmitForm,
        handleInputChange,
        serviceRequestLoading,
        loading,
        data,
        selectedFiles,
        handleOnFileSelect,
        error,
        serviceRequestError,
        uploading,
    } = useServiceRequestController();

    const {
        lazyServiceRequests,
        loading: lazyLoading,
        called: lazyCalled,
        getServiceRequests: updateServicesList,
    } = useQueryServiceRequest();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setServiceTypeValue(String(serviceType.id)));
        dispatch(setRentalObjectSelectValue(String(selectedObjectId)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //update services list only when mutation response is received
        if (!error && !loading && !!lazyServiceRequests && !!data) updateServicesList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, data, error]);

    useEffect(() => {
        // close only when services updated
        if (!error && lazyCalled && !lazyLoading && !!lazyServiceRequests) handleParentPanelClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyLoading, lazyCalled, lazyServiceRequests, error]);

    const namingStructure = {
        base: 'service-request-form',
        fieldWrapper: 'wrapper',
        formTitle: 'form-title',
        objectSelect: 'rental-object-select',
        objectSelectLabel: 'rental-object-select-label',
        requestTitle: 'request-title',
        requestDescription: 'request-description',
        uploadFiles: 'upload-files',
        files: 'files',
        errorContainer: 'error-container',
    };
    const classes = makeComponentClasses(namingStructure, customClasses);
    const formIds = makeComponentIds(namingStructure, idBase);

    const fieldArgs = {
        required: true,
        fullWidth: true,
    };

    const activeAgreementObjects = new Map<number, IAgreementObjectInstanceData>();

    if (agreements && Object.values(agreements).length) {
        for (const agreement of Object.values(agreements)) {
            for (const object of agreement.objects) {
                if (
                    AgreementStatus.isActive({ agreement, objectId: object.id }) &&
                    !activeAgreementObjects.has(object.id)
                ) {
                    activeAgreementObjects.set(object.id, object);
                }
            }
        }
    }

    const objectsItems =
        activeAgreementObjects.size && !!coreSelectedAgreementId ? (
            Array.from(activeAgreementObjects).map(([key, item]) => (
                <MenuItem key={key} value={key}>
                    {AgreementDetailsViewData.buildAddress(item)}
                </MenuItem>
            ))
        ) : (
            <MenuItem value="0">
                {coreSelectedAgreementId
                    ? t('rentalObject.agreementStatus.missingActiveAgreementObjects')
                    : t('rentalObject.agreementStatus.missingActiveAgreements')}
            </MenuItem>
        );

    const uploadingText = uploading && uploading > 0 ? `Uploading: ${uploading}%` : '';
    const submitStatusText = uploading === 100 ? `Processing...` : uploadingText;

    // @ts-ignore
    return (
        <form className={classes.base}>
            <h3 className={classes.formTitle}>{serviceType.title}</h3>
            <div className={classes.fieldWrapper}>
                <InputLabel required={true} className={classes.objectSelectLabel} id={formIds.objectSelectLabel}>
                    {t('maintenanceServices.form.objectSelectLabel')}
                </InputLabel>
                <Select
                    className={classes.objectSelect}
                    labelId={formIds.objectSelectLabel}
                    id={formIds.objectSelect}
                    name={'rentalObjectSelect'}
                    onChange={handleSelectChange}
                    variant="outlined"
                    value={rentalObjectSelect || '0'}
                    {...fieldArgs}
                    disabled={!coreSelectedAgreementId}
                >
                    {objectsItems}
                </Select>
            </div>
            <div className={classes.fieldWrapper}>
                <TextField
                    className={classes.requestTitle}
                    id={formIds.requestTitle}
                    name={'requestTitle'}
                    variant="outlined"
                    label={t('maintenanceServices.form.requestTitle')}
                    onChange={handleInputChange}
                    {...fieldArgs}
                />
            </div>
            <div className={classes.fieldWrapper}>
                <TextField
                    className={classes.requestDescription}
                    id={formIds.requestDescription}
                    name={'requestDescription'}
                    variant="outlined"
                    label={t('maintenanceServices.form.requestDescription')}
                    multiline={true}
                    minRows={5}
                    onChange={handleInputChange}
                    {...fieldArgs}
                />
            </div>
            <div className={classes.fieldWrapper}>
                <FileUploadButton
                    className={classes.fileUpload}
                    id={formIds.uploadFiles}
                    title={t('maintenanceServices.form.uploadFiles')}
                    handleChange={handleOnFileSelect}
                />
                <SelectedFiles className={classes.files} files={selectedFiles || []} />
            </div>
            <div className={classes.fieldWrapper}>
                <SubmitButton
                    loading={serviceRequestLoading}
                    loadingText={submitStatusText || t('notices.loading')}
                    actionHandler={handleSubmitForm}
                    text={t('maintenanceServices.form.makeRequest')}
                    disabled={!coreSelectedAgreementId || serviceRequestLoading || lazyLoading}
                />
            </div>
            <div className={classes.fieldWrapper}>
                <div className={classes.errorContainer}>
                    {((serviceRequestError || error) as AxiosError<any> | ApolloError | undefined) && (
                        <ErrorNotice
                            //@ts-ignore missing clientError
                            error={serviceRequestError || error}
                            errorHeaderPrefixText={
                                serviceRequestError && 'message' in serviceRequestError
                                    ? serviceRequestError.message
                                    : ''
                            }
                            errorBodyPrefixText={
                                !!serviceRequestError && 'debugMessage' in serviceRequestError
                                    ? String(serviceRequestError['debugMessage'])
                                    : ''
                            }
                        />
                    )}
                </div>
            </div>
        </form>
    );
};

export default ServiceRequestForm;
