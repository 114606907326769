import { call, fork, put, select, take } from 'redux-saga/effects';
import { messagingActions } from 'messaging/redux/messagingActions';
import { AppState } from 'core/redux/rootReducer';
import { FIREBASE, MESSAGING } from 'core/datasets/action';
import { child, DataSnapshot, get, getDatabase, ref } from 'firebase/database';
import { onItemAddedWatcher } from 'firebase/saga/workers/onItemAddedWatcher';
import { createMessagingTenantLandlordChannel } from '../../gql/messagigMutations';

export function* fetchChannelsList(): Generator {
    let firebaseUser = (yield select(({ firebase }: AppState) => firebase.user)) as any;

    let firebaseToken = (yield select(({ firebase }: AppState) => firebase.token)) as any;
    if (!firebaseToken) {
        yield take(FIREBASE.SET_TOKEN);
        firebaseToken = (yield select(({ firebase }: AppState) => firebase.token)) as any;
    }
    if (!firebaseUser) {
        yield take([FIREBASE.SET_FIREBASE_USER]);
        firebaseUser = (yield select(({ firebase }: AppState) => firebase.user)) as any;
    }

    const agreementUid = (yield select(({ agreements }: AppState) => agreements.selectedAgreement?.uuid)) as string;

    if (firebaseUser) {
        const dbRef = ref(getDatabase());
        const channels = (yield call(() =>
            get(child(dbRef, 'userChannels/' + firebaseUser.uid)).catch((error) => {
                console.error(error);
            }),
        )) as DataSnapshot;

        if (!channels.exists()) {
            if (agreementUid && firebaseToken) {
                yield put(
                    messagingActions.createNewChannelWithLandlord(
                        createMessagingTenantLandlordChannel,
                        agreementUid,
                        firebaseToken,
                    ),
                );
            }
        }

        yield fork(
            onItemAddedWatcher,
            'userChannels/' + firebaseUser.uid,
            MESSAGING.ADD_CHANNEL_KEY,
            (data) => data.key,
            true,
        );
    }
}
