import { agreementActions } from 'agreement/redux/agreementActions';
import { AGREEMENT } from 'core/datasets/action';
import { put } from 'redux-saga/effects';

export function* handleAgreementsLoadingStateChange({
    type,
}: {
    type: typeof AGREEMENT[keyof typeof AGREEMENT];
}): Generator {
    yield put(
        agreementActions.changeLoadingState(
            type === AGREEMENT.FETCH_ALL_AGREEMENTS || type === AGREEMENT.INIT_AGREEMENTS_FETCHING,
        ),
    );
}
