import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { IServiceRequestTypeV2, ServiceRequestTypeTitlesMap } from 'serviceRequest/types/serviceRequest';
import { QUERY_FETCH_POLICY, QueryStatusData } from '../../../../core/hooks/useQueryHelper';
import { keyBy } from 'lodash';

const ServiceRequestTypesDocument = gql`
    query serviceRequestTypesV2 {
        serviceRequestTypesV2 {
            id
            internalTitle
            isActive
            isDefault
            titles {
                langCode
                text
            }
        }
    }
`;

const useQueryServiceRequestTypes = (): QueryServiceRequestTypesReturn => {
    const { loading, error, data } = useQuery<{ serviceRequestTypesV2: IServiceRequestTypeV2 }>(
        ServiceRequestTypesDocument,
        {
            ...QUERY_FETCH_POLICY,
        },
    );
    const [handleServiceRequestTypes, { loading: lazyLoading, error: lazyError, data: lazyData }] = useLazyQuery(
        ServiceRequestTypesDocument,
        {
            ...QUERY_FETCH_POLICY,
        },
    );

    const serviceTypesList: IServiceRequestTypeV2[] = !!data
        ? data.serviceRequestTypesV2
        : !!lazyData
        ? lazyData.serviceRequestTypesV2
        : [];

    const serviceRequestTypeMap = keyBy(serviceTypesList, ({ id }) => String(id));

    const serviceRequestTypeTitlesMap = serviceTypesList.reduce<ServiceRequestTypeTitlesMap>(
        (acc, { id, titles, internalTitle }) => {
            acc[String(id)] = titles.reduce<Record<string, string>>((acc, { text, langCode }) => {
                acc[langCode] = text;
                return acc;
            }, {});
            acc[String(id)]['internalTitle'] = internalTitle || '';

            return acc;
        },
        {},
    );

    return {
        loading: lazyLoading || loading,
        error: lazyError || error,
        data: serviceTypesList,
        serviceRequestTypeMap,
        serviceRequestTypeTitlesMap,
        handleServiceRequestTypes,
    };
};
export default useQueryServiceRequestTypes;

type QueryServiceRequestTypesReturn = QueryStatusData & {
    data: IServiceRequestTypeV2[];
    serviceRequestTypeMap: Record<string, IServiceRequestTypeV2>;
    serviceRequestTypeTitlesMap: ServiceRequestTypeTitlesMap;
    handleServiceRequestTypes: () => void;
};
