import { NotificationAlert } from 'core/classes/NotificationAlert';
import { coreActions } from 'core/redux/actions/coreActions';
import { TenantRegistrationData } from 'tenantUser/types/TenantRegistrationData';
import { gql, useMutation } from '@apollo/client';
import { ApolloError } from '@apollo/react-hooks';
import { useState } from 'react';
import { LS_KEY } from 'core/datasets/storages/lsKeys';
import { setLoginSuccess } from 'tenantUser/redux/authActions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const RegisterTenantDocument = gql`
    mutation registerTenant($tenantInput: TenantInput!) {
        registerTenant(tenantInput: $tenantInput) {
            isValid
            login {
                expiration_date
                refresh_token
                token
                user {
                    firstName
                    email
                    id
                    lastName
                    phone
                }
            }
            message
        }
    }
`;

interface MutationRegisterTenantProps {
    hash: string;
}

const useMutationRegisterTenant = ({ hash }: MutationRegisterTenantProps): MutationRegisterTenantReturnType => {
    const { t } = useTranslation();

    const [registerTenant, { loading, error }] = useMutation(RegisterTenantDocument);

    const [responseError, setResponseError] = useState<ApolloError | undefined>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleRegistration = (tenant: TenantRegistrationData) => {
        const { email, firstName, lastName, password, phone } = tenant;

        registerTenant({
            variables: {
                tenantInput: {
                    email,
                    firstName,
                    lastName,
                    password,
                    phone,
                    inviteHash: hash,
                },
            },
        })
            .then(({ data }) => {
                const { registerTenant } = data;

                if (!registerTenant?.isValid) {
                    setResponseError(
                        () =>
                            new ApolloError({
                                errorMessage: registerTenant?.message,
                            }),
                    );

                    return;
                }

                const { login } = registerTenant;
                const successMessage = t('notices.registrationIsDone');

                if (login) {
                    localStorage.setItem(LS_KEY, JSON.stringify(login));

                    dispatch(setLoginSuccess(login));

                    navigate('/');

                    dispatch(
                        coreActions.notification.set({
                            message: successMessage,
                            level: NotificationAlert.SUCCESS,
                        }),
                    );
                    return;
                }

                navigate('/login');

                dispatch(
                    coreActions.notification.set({
                        message: successMessage,
                        level: NotificationAlert.SUCCESS,
                    }),
                );
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return { handleRegistration, loading, error: error || responseError };
};
export default useMutationRegisterTenant;

type MutationRegisterTenantReturnType = {
    handleRegistration: (tenant: TenantRegistrationData) => void;
    loading: boolean;
    error?: ApolloError;
};
