import { AgreementType } from 'agreement/classes/AgreementType';
import { IObjectAgreementScopeIData, ObjectAgreementScope } from 'agreement/classes/ObjectAgreementScope';
import IAgreementInstanceData from 'agreement/types/IAgreementData';
import IAgreementPropsData from 'agreement/types/IAgreementPropsData';
import LicensePlate from 'agreement/types/LicensePlate';
import AgreementObject from 'agreementObject/classes/AgreementObject';
import IAgreementObjectInstanceData from 'agreementObject/types/IAgreementObjectData';
import IAgreementObjectInitData from 'agreementObject/types/IAgreementObjectInitData';
import Serializable from 'core/types/Serialisable';
import { DateTime } from 'luxon';
import { ILicensePlate } from 'rentalObject/types/rentalObjects';
import { InceptionUnit } from 'smartHome/inception/types/inception';
import { UserData } from 'tenantUser/types/user';

class Agreement implements Serializable {
    get type(): AgreementType {
        return this._type;
    }

    set type(value: AgreementType) {
        this._type = value;
    }

    private _type: AgreementType = AgreementType.SINGLE_OBJECT;
    private _id: number | null = null;
    private _uuid: string | null = null;
    private _agreementNumber: number | string | null = null;
    private _tenants: unknown;
    private _billingSender: unknown;
    private _agreementDate: DateTime | null = null;
    private _agreementEnd: DateTime | null = null;
    private _paymentAmount: number | null = null;
    private _prePayment: number | null = null;
    private _paymentDate: number | null = null;
    private _deposit: number | null = null;
    private _objects: IAgreementObjectInstanceData[] = [];
    private _currency: string | null = null;
    private _licensePlates: ILicensePlate[] | null = null;
    private _parentAgreementId: number | null = null;
    private _smartHomeUnits: InceptionUnit[] | null;

    constructor(props: IAgreementPropsData) {
        this._smartHomeUnits = props.smartHomeUnits;

        const isMultiObject = props?.rentalAgreementObjects?.length;
        const isSingleObject = props.rentalObject !== null;

        if (!isSingleObject && !isMultiObject) {
            throw Error(`Object has no data about single or muiltuple rental objects: ${props.id}`);
        }

        if (props?.rentalAgreementObjects?.length) {
            this.initMultiObjectAgreement(props);
        } else {
            this.initSingleObjectAgreement(props);
        }
    }

    get parentAgreementId(): number | null {
        return this._parentAgreementId;
    }

    set parentAgreementId(value: number | null) {
        this._parentAgreementId = value;
    }

    get objects(): IAgreementObjectInstanceData[] {
        if (this._objects.length) {
            return this._objects;
        }

        throw new ReferenceError('Undefined object id');
    }

    addObject(value: unknown): void {
        if (value) {
            this._objects.push(value as IAgreementObjectInstanceData);
        }
    }

    get licensePlates(): LicensePlate[] | null {
        return this._licensePlates;
    }

    set licensePlates(plates: LicensePlate[] | null) {
        const _plates: ILicensePlate[] = [];

        if (plates && Array.isArray(plates)) {
            for (const { value } of plates) {
                _plates.push({ value });
            }
        }

        this._licensePlates = _plates;
    }
    get currency() {
        return this._currency;
    }

    set currency(value) {
        this._currency = value;
    }
    get uuid() {
        return this._uuid;
    }

    set uuid(value) {
        this._uuid = value;
    }
    get deposit() {
        return this._deposit;
    }

    set deposit(value) {
        this._deposit = value;
    }
    get paymentDate() {
        return this._paymentDate;
    }

    set paymentDate(value) {
        this._paymentDate = value;
    }
    get prePayment(): number | null {
        return this._prePayment;
    }

    set prePayment(value) {
        this._prePayment = value;
    }
    get paymentAmount(): number | null {
        return this._paymentAmount;
    }

    set paymentAmount(value) {
        this._paymentAmount = value;
    }
    get agreementEnd(): DateTime | null {
        return this._agreementEnd;
    }

    set agreementEnd(value: unknown) {
        this._agreementEnd = value && typeof value === 'string' ? DateTime.fromSQL(value) : null;
    }
    get agreementDate(): DateTime | null {
        return this._agreementDate;
    }

    set agreementDate(value: unknown) {
        this._agreementDate = value && typeof value === 'string' ? DateTime.fromSQL(value) : null;
    }

    get billingSender(): unknown {
        return this._billingSender;
    }

    set billingSender(value) {
        this._billingSender = value;
    }
    get tenants(): unknown {
        return this._tenants;
    }

    set tenants(value) {
        this._tenants = value;
    }
    get agreementNumber(): number | string | null {
        if (this._agreementNumber || this._agreementNumber === null) return this._agreementNumber;

        throw new ReferenceError('Undefined');
    }

    set agreementNumber(value: unknown) {
        if (value === null || typeof value === 'number' || typeof value === 'string') {
            this._agreementNumber = value;
        }
    }

    get id(): number {
        if (this._id) return this._id;
        throw new ReferenceError('Undefined');
    }

    set id(value) {
        this._id = value;
    }

    get agreementDateFormatted(): string | null {
        return this.agreementDate && this.agreementDate?.isValid ? this.agreementDate.toFormat('dd.LL.yyyy') : null;
    }
    get agreementEndFormatted(): string | null {
        return this.agreementEnd && this.agreementEnd?.isValid ? this.agreementEnd.toFormat('dd.LL.yyyy') : null;
    }

    /**
     * Agreement is active when: <strong> agreementStartDate &lt;= today && (agreementEnd >= today || null)</strong>
     * @param agreement
     */
    static isActive(agreement: IAgreementInstanceData | IObjectAgreementScopeIData): boolean {
        // agreementStartDate <= today && (agreementEnd >= today || null)
        const agreementStartDate = 'agreementDate' in agreement ? agreement.agreementDate : agreement.agreementStart;

        if (!agreementStartDate || !DateTime.fromSQL(agreementStartDate).isValid) return false;

        return (
            !!agreementStartDate &&
            DateTime.fromSQL(agreementStartDate).startOf('day') <= DateTime.now() &&
            (agreement.agreementEnd === null ||
                (DateTime.fromSQL(agreement.agreementEnd).isValid &&
                    DateTime.fromSQL(agreement.agreementEnd).endOf('day') >= DateTime.now()))
        );
    }

    static isFuture(agreement: IAgreementInstanceData | IObjectAgreementScopeIData): boolean {
        const agreementStartDate = 'agreementDate' in agreement ? agreement.agreementDate : agreement.agreementStart;

        if (!agreementStartDate || !DateTime.fromSQL(agreementStartDate).isValid) return false;

        return !!agreementStartDate && DateTime.fromSQL(agreementStartDate).startOf('day') > DateTime.now();
    }

    static hasObject(agreement: IAgreementInstanceData, objectId: number): boolean {
        return !!objectId && agreement.objects.findIndex((agreementObject) => agreementObject.id === objectId) > -1;
    }

    get all(): IAgreementInstanceData {
        return {
            id: this.id as number,
            type: this.type,
            agreementNumber: this.agreementNumber as number,
            tenants: this.tenants as UserData[],
            billingSender: this.billingSender,
            agreementDate: this.agreementDate?.toSQL() || null,
            agreementEnd: this.agreementEnd?.toSQL() || null,
            paymentAmount: this.paymentAmount,
            prePayment: this.prePayment,
            paymentDate: this.paymentDate as number | null,
            deposit: this.deposit as number | null,
            uuid: this.uuid as string,
            currency: this.currency as string,
            licensePlates: this.licensePlates as ILicensePlate[],
            objects: this.objects,
            parentAgreementId: this.parentAgreementId,
            smartHomeUnits: this._smartHomeUnits,
        };
    }

    toJSON(): IAgreementInstanceData {
        return this.all;
    }

    private initSingleObjectAgreement(props: IAgreementPropsData) {
        this.type = AgreementType.SINGLE_OBJECT;
        this.assignGeneralAgreementData(props);

        const { paymentAmount, prePayment, deposit, currency, paymentDate, licensePlates, rentalObject } = props;

        if (!rentalObject) {
            throw new TypeError('Rental object must be defined');
        }

        this.paymentAmount = paymentAmount;
        this.prePayment = prePayment;
        this.paymentDate = paymentDate;
        this.prePayment = prePayment;
        this.deposit = deposit;
        this.currency = currency;
        this.licensePlates = licensePlates;

        this.addObject(new AgreementObject({ ...rentalObject } as IAgreementObjectInstanceData).all);
    }

    private initMultiObjectAgreement(props: IAgreementPropsData) {
        this.type = AgreementType.MULTI_OBJECT;
        this.assignGeneralAgreementData(props);

        const { rentalAgreementObjects } = props;

        if (!rentalAgreementObjects) {
            throw new TypeError('rentalAgreementObjects must be defined for multi-object Agreement');
        }

        for (const agreementObject of rentalAgreementObjects) {
            const agreementScope = new ObjectAgreementScope({
                ...agreementObject,
                parentAgreementId: props.id,
            }).all;

            this.addObject(
                new AgreementObject({
                    ...agreementObject.rentalObject,
                    agreement: agreementScope,
                } as IAgreementObjectInitData).all,
            );
        }
    }

    private assignGeneralAgreementData({
        id,
        agreementNumber,
        tenants,
        billingSender,
        agreementDate,
        agreementEnd,
        paymentDate,
        uuid,
        currency,
    }: IAgreementPropsData) {
        this.id = id;
        this.agreementNumber = agreementNumber;
        this.tenants = tenants;
        this.billingSender = billingSender;
        this.agreementDate = agreementDate;
        this.agreementEnd = agreementEnd;
        this.paymentDate = paymentDate;
        this.uuid = uuid;
        this.currency = currency;
    }
}

export default Agreement;
