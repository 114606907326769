import { EventFirebase } from 'firebase/classes';
import { DataSnapshot, getDatabase, off, ref } from 'firebase/database';
import { put, take } from 'redux-saga/effects';

export function* onItemAddedWatcher(
    path: string,
    actionType: string,
    transformer?: (data: DataSnapshot) => any,
    disableOldEvent = false,
    limitToLast?: number,
) {
    if (disableOldEvent) {
        const db = getDatabase();
        const channelRef = ref(db, path);
        off(channelRef, EventFirebase.CHILD_ADDED);
    }

    const updateChannel = EventFirebase.createEventChannelFirebase(path, EventFirebase.CHILD_ADDED, limitToLast);

    while (true) {
        const item: DataSnapshot = yield take(updateChannel);
        const payload = transformer ? transformer(item) : item;
        if (payload !== null || payload !== undefined) {
            yield put({ type: actionType, payload });
        }
    }
}
