import PAGE_CONTEXT from '../../datasets/page_context';
import storages from '../../datasets/storages';
import USER_SETTINGS from '../../datasets/storages/user_setting.json';
import { store } from 'core/redux/store';

export const STORAGE = {
    APP_NAME: 'tenant-app',
    APP_KEYS: {
        REFRESH_TOKEN: 'refresh_token',
        TOKEN: 'token',
        SERVICE_REQUESTS: PAGE_CONTEXT.SERVICE_REQUESTS,
    },
} as const;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStorage = (name?: string | StoreNameType) => {
    const storageRaw = localStorage.getItem(!!name ? name : STORAGE.APP_NAME);
    return storageRaw && typeof JSON.parse(storageRaw as string) === 'object' ? JSON.parse(storageRaw as string) : null;
};

type AppStorageKeyType = typeof STORAGE.APP_KEYS[keyof typeof STORAGE.APP_KEYS];
type StoreNameType = keyof typeof storages;
type StoreItemsType = typeof storages[keyof typeof storages];
type StoreItemKeyType = StoreItemsType[keyof StoreItemsType];

export const getValueFromStorage = (
    key: AppStorageKeyType | StoreItemKeyType | string,
    store?: StoreNameType | string,
): string => {
    const storage = getStorage(store);

    return !!storage && key in storage ? (storage[key] as string) : '';
};

export const setObjectValueInStorage = (
    key: typeof STORAGE.APP_KEYS[keyof typeof STORAGE.APP_KEYS],
    value: { [id: string]: string | number },
): void => {
    if (!!key && !!value) {
        const storage = getStorage();
        const newStorage = !!storage
            ? {
                  ...storage,
                  [key]: value,
              }
            : { [key]: value };
        localStorage.setItem(STORAGE.APP_NAME, JSON.stringify(newStorage));
    }
};

export const getToken = (): string => store.getState().auth.token;

export const getRefreshToken = (): string => store.getState().auth.refresh_token;

export const removeStorage = (): void => localStorage.removeItem(STORAGE.APP_NAME);

export const getUserEmail = (): string => {
    const storage = getStorage();

    const user = !!storage && 'user' in storage ? storage['user'] : null;

    return !!user ? user.email : '';
};

export const getUserSettingFromStorage = (key: StoreItemKeyType | string): string => {
    return getValueFromStorage(key, storages.STORE_NAMES.USER_SETTINGS);
};

export const getSelectedAgreementIdFromStorage = (): string => {
    return getUserSettingFromStorage(USER_SETTINGS.selectedObjectAgreementID);
};
