import { Device, LarvaErrorEvent, LarvaEvent, LarvaMessage } from '@larva.io/clouddevice';
import { LarvaErrorListener, LarvaEventListener, Options, Token } from '@larva.io/clouddevice/dist/types/interface';
import LarvaQueuedRequest from 'smartHome/larva/classes/LarvaQueuedRequest';
import ILarvaWebSocketClient from 'smartHome/larva/types/ILarvaWebSocketClient';
import { larvaId } from '../utils/makeId';

class LarvaWsClientWrapper extends Device implements ILarvaWebSocketClient {
    private readonly _deviceId: string;
    private readonly _unitId: string;
    public readonly fullId: string;

    private errorHandler: LarvaErrorListener;

    constructor(
        deviceId: string,
        unit: string,
        token: Token,
        errorHandler: LarvaErrorListener,
        errorRemoveHandler: LarvaEventListener,
        options?: Options,
    ) {
        super(deviceId, unit, token, options);

        this._deviceId = deviceId;
        this._unitId = unit;
        this.fullId = larvaId(this._unitId, this._deviceId);
        this.errorHandler = errorHandler;

        this.addEventListener('error', this.errorHandler);
        this.addEventListener('closed', errorHandler);
        this.addEventListener('open', errorRemoveHandler);
    }

    get connectionId(): string {
        return `${this._unitId}_${this._deviceId}`;
    }

    async open(onOpenCallback?: (id: string) => void | ((id: string) => Promise<void>)): Promise<void> {
        if (super.readyState() === 3) {
            await super.open();
        }

        onOpenCallback && onOpenCallback(this.connectionId);
    }

    readyState(callback?: (code: number) => void): number {
        // readyState: 0 CONNECTING, 1 OPEN, 2 CLOSING, 3 CLOSED
        let code = -1; // not available
        try {
            code = super.readyState();
        } catch (error) {
            console.error('Failed to check the socket state =>', error);
        }
        callback && callback(code);
        return code;
    }

    async handleNodeOutput(event: LarvaEvent): Promise<void> {
        return super.handleNodeOutput(event);
    }

    async handleNodeRequest(event: LarvaEvent): Promise<void> {
        return super.handleNodeRequest(event);
    }

    async handleRequest(request: LarvaQueuedRequest): Promise<void> {
        if (request.type === LarvaQueuedRequest.NODE_REQUEST) {
            await this.handleNodeRequest(request.request as LarvaEvent);
        }
        if (request.type === LarvaQueuedRequest.NODE_OUTPUT) {
            await this.handleNodeOutput(request.request as LarvaEvent);
        }
    }

    async rawRequest(topic: string, data?: unknown, token?: string): Promise<LarvaMessage> {
        let result: LarvaMessage = { payload: {}, topic: '' };

        try {
            result = await super.rawRequest(topic, data, token);
        } catch (error) {
            this.errorHandler(error as LarvaErrorEvent);
        }

        return result;
    }

    async close(reason?: number): Promise<void> {
        try {
            if (super.readyState() === WebSocket.OPEN) {
                await super.close(reason);
            }

            return;
        } catch (e) {
            console.error('Failed to close: ', e);
        }

        return void 0;
    }
}
export default LarvaWsClientWrapper;
